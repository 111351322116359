.App {
  background-color: #fcfcfc;
}

.underline {
  text-decoration: underline;
}

.information-line {
  /* display: flex; */
  font-size: smaller;
  gap: 8px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*page1*/
button {
  cursor: pointer;
}

.connexion {
  background-color: #f5f5f5;
  width: max-content;
  /* padding: 20px; */
  display: flex;
  border-radius: 10px;
  border: solid 1px #282c34;
  height: fit-content;
  font-size: small;
  width: 40%;
  justify-content: center;
  padding: 50px 50px;
}

.bloc-jaune {
  display: flex;
  justify-content: center;

  width: 70%;
  align-items: center;
  width: 50%;
}

.jat-logo {
  margin: 20px;
  width: 250px;
}

label {
  display: flex;
  font-size: small;
  flex-direction: column;
  margin-top: 10px;
}

.input-30 {
  height: 30px;
  border-radius: 5px;
  border: solid 1px;
  background-color: white;
  padding-left: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
}

a {
  font-size: smaller;
  font-weight: 500;
  color: #282c34;
}

.page-log-in {
  display: flex;
  justify-content: space-between;
}

.input-connexion {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.img-accueil {
  object-fit: cover;
  height: 100vh;
  width: 50%;
}

/*FinPage1*/
.icon-button {
  background-color: white;
  border: none;
  padding-left: 0;
}

.icon-hover {
  transition: transform 0.1s ease-in-out;
}

.icon-hover:hover {
  transform: scale(1.2);
  /* Increase the scale to your desired size */
}

/*Page 2*/

.page-list-devis {
  padding: 30px;
}

.header-new-devis {
  display: flex;
  justify-content: space-between;
  flex: 10;
  gap: 20px;
}

.header-devis {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.header-new-devis-btn {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  justify-content: flex-start;
}

.item-line {
  background-color: white;
  border: solid 1px #282c34;
  border-radius: 5px;
  padding: 10px 0px;
  text-align: center;
}

.search-label {
  margin-top: 0px;
}

.item-line:hover {
  cursor: pointer;
}

.logo-line {
  padding: 0px;
  text-align: center;
}

.th-list-devis {
  font-size: medium;
  font-weight: 600;
  margin-bottom: 20px;
  /* font-weight: 400; */
}

.th-search {
  font-size: xx-small;
  font-weight: 600;
  position: sticky;
  top: 0;
  background: white;
  /* font-weight: 400; */
}

.th-search:hover {
  cursor: pointer;
  /* font-weight: 400; */
}

.th-list-devis:hover {
  cursor: pointer;
  /* font-weight: 400; */
}

/*Page3*/

.info-devis {
  display: flex;
  justify-content: space-between;
}

.bloc-info-devis {
  background-color: white;
  border: solid 1px #282c34;
  border-radius: 5px;
  padding: 0px 10px;
  margin: 0px 20px 20px 20px;
}

.info-organisation {
  width: 30%;
}

.info-contact {
  width: 30%;
}

.info-budget {
  width: 30%;
}

.top-table-watch-devis {
  background-color: white;
  border: solid 1px #282c34;
  border-radius: 5px;
  padding: 10px 20px;
}

h2 {
  font-weight: 500;
  text-decoration: underline;
}

h3 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: smaller;
}

h4 {
  font-weight: 500;
  text-transform: uppercase;
  font-size: small;
  margin: 2px;
  font-weight: bold;
}

.item {
  box-sizing: border-box !important;
  background-color: white;
  border: solid 1px #282c34;

  border-radius: 5px;
  padding: 8px 6px;
  font-size: x-small;
}

.input-item {
  border: none;
  box-sizing: border-box !important;
  /* padding: -1px !important; */
  z-index: -7;
  font-size: x-small;
}

.input-table {
  box-sizing: border-box !important;
  font-size: x-small;
  border: none;
  outline: solid 2px;
  border-radius: 5px;
  width: 98%;
  padding: 8px 0px;
  text-align: center;
  /* margin: -1px !important; */
}

.table-product {
  margin: 20px;
  margin-right: 40px;
  border-spacing: 8px;
  width: 100%;
}

.table-category {
  color: #c4664a;
  font-weight: bold;
  margin: 5px;
}

.div-offer {
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

th {
  font-size: 9px;
}

tr {
  font-size: smaller;
}

.div-page-devis {
  display: flex;
}

.column-offer {
  background-color: white;
  border: solid 1px #282c34;
  border-radius: 5px;
  padding: 10px;
  padding-right: 20px;
  /* width: max-content; */
}

.th-view {
  font-weight: 600;
  position: sticky;
  top: 0;
  background: white;
  padding: 10px 0px;
}

.tr-offer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.div-button-action {
  display: flex;
  flex-direction: column;
  align-items: end;
  text-align: center;
  gap: 10px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 2;
}

.button-action {
  background-color: white;
  border: solid 1px #282c34;
  border-radius: 5px;
  padding: 7px 20px 7px 7px;
  display: flex;
  justify-content: center;
  padding: 10px 30px 10px 40px;
  gap: 20px;
  align-items: center;
  width: 80%;
  transition: background-color 0.3s;
  width: 100%;
}

.button-action:active {
  background-color: rgb(201, 200, 200);
}

/*Page nouveux devis formulaire*/

.nv-devis {
  padding: 0px 40px;
  background-color: #fcfcfc;
}

h1 {
  font-weight: 400;
}

select {
  height: 35px;
  border-radius: 5px;
  border: solid 1px;
  background-color: white;
  text-align: center;
}

.input-double {
  display: flex;
  gap: 20px;
}

.label-input {
  flex: 1;
}

.input-form-double {
  height: 30px;
  border-radius: 5px;
  border: solid 1px;
  background-color: white;
  padding-left: 5px;
}

.form {
  display: flex;
  justify-content: space-around;
  gap: 40px;
}

.input-comment {
  height: 100px;
  border-radius: 5px;
  border: solid 1px;
  resize: none;
}

.checkbox {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 12px;
}

.budget {
  display: flex;
  align-items: end;
  gap: 10px;
}

.text-underline {
  font-size: smaller;
  font-weight: 500;
  text-decoration: underline;
}

.select-lines {
  display: flex;
  align-items: end;
  gap: 10px;
}

.button-orange {
  background-color: #c4664a;
  color: white;
  padding: 10px 20px;
  border: solid 1px #282c34;
  border-radius: 5px;
  height: fit-content;
  transition: background-color 0.3s;
}

.button-orange:active {
  background-color: #994a32;
}

.input-form {
  height: 30px;
  border-radius: 5px;
  border: solid 1px;
  background-color: white;
  padding-left: 5px;
}

/*page de recherche*/

.div-search-page {
  display: flex;
  justify-content: space-between;
}

.search-block {
  background-color: white;
  border: solid 1px #282c34;
  border-radius: 5px;
  padding: 20px;
}

.div-search-block {
  flex: 4;
  margin: 20px 0px 20px 20px;
}

.search-bar {
  width: 100%;
}

.your-selection {
  background-color: white;
  border: solid 1px #282c34;
  border-radius: 5px;
  padding: 10px;
  height: 300px;
  overflow: scroll;
}

.div-selection-line {
  display: flex;
  gap: 10px;
}

.div-side-search-page {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px;
  flex: 6;
}

.table-search {
  width: 100%;
  margin: 0px 0px 0px 00px;
  border-spacing: 8px;
  height: auto;
}

::-webkit-scrollbar {
  width: 10px;
  /* Largeur de la barre de défilement */
}

::-webkit-scrollbar-track {
  background: #ffffff;
  /* Couleur de fond de la piste */
}

::-webkit-scrollbar-thumb {
  background: #eec69f;
  /* Couleur de la poignée de défilement */
  border: #282c34 solid 1px;
  border-radius: 10px;
  height: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #c4664a;
  /* Couleur de la poignée de défilement au survol */
}

html {
  scrollbar-width: 15px;
  scrollbar-color: #eec69f #ffffff;
}

/*final page*/
.div-final-page {
  display: flex;
  /* gap: 20px; */
  /* margin: 20px */
}

.div-deux-final-page {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 2;
  /* margin: 20px; */
  margin-left: 7px;
  margin-right: 0px;
}

.final-table-product {
  /* display: flex; */
  flex-direction: column;
  margin: 20px 0px 20px 20px;
  border-spacing: 8px;
}

.colored-toast.swal2-icon-question {
  background-color: #ffffff !important;
}

.colored-toast .swal2-html-container {
  color: #c4664a;
}

.scrollbar-final {
  overflow: scroll;
}

.tr-final {
  font-weight: 600;
  position: sticky;
  top: 0;
  background: white;
  z-index: 78;
  border-radius: 0;
}

.div-stat {
  background-color: white;
  border: solid 1px #282c34;
  border-radius: 10px;
  padding: 10px;
  width: 30%;
}

.div-stat-1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.p-little {
  font-size: 12px;
  margin: 0px;
  font-style: italic;
}

.p-big {
  font-size: 16px;
  margin: 4px 0px;
}

.title-stat {
  font-weight: bold;
  font-size: 12px;
  margin: 0px;
}

.line-stat {
  display: flex;
  gap: 30px;
  margin-top: 20px;
  width: 100%;
}

.header-dashboard {
  display: flex;

  flex-direction: column;
}
